body {
  /* overflow-y: hidden; */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}
/* .lesson-header{
  overflow: scroll;
} */

.product{
  height: 120px;
background-color: rgb(216, 219, 221);
display: flex;
  align-items: center;
}
.product-title{
  font-size: 22px;
  font-weight: bold;

}
.login-card{
  height: 100%;
  width: 400px;
  margin-top: 8em;
}
.login-card  .card-body {
  height: 80%;
  width: 100%;
}


a {
  text-decoration: none ;
  color: black;
}
a:hover{
  text-decoration: none ;
  color: black;
}
Link{
  color: black;
}
.accordion-button:focus{
  border-color: none!important;
}
.accordion-button:not(.collapsed){
  color: black;
}
.accordion-button:not(.collapsed):after {
background-image: url(../src/images/up-arrow.png)!important;
transform: rotate(-180deg);
background-size: contain;
}
.accordion-button::after{
  background-image: url(../src/images/up-arrow.png)!important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
