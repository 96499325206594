body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  /* overflow-y: hidden; */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}
/* .lesson-header{
  overflow: scroll;
} */

.product{
  height: 120px;
background-color: rgb(216, 219, 221);
display: flex;
  align-items: center;
}
.product-title{
  font-size: 22px;
  font-weight: bold;

}
.login-card{
  height: 100%;
  width: 400px;
  margin-top: 8em;
}
.login-card  .card-body {
  height: 80%;
  width: 100%;
}


a {
  text-decoration: none ;
  color: black;
}
a:hover{
  text-decoration: none ;
  color: black;
}
Link{
  color: black;
}
.accordion-button:focus{
  border-color: none!important;
}
.accordion-button:not(.collapsed){
  color: black;
}
.accordion-button:not(.collapsed):after {
background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAMAAAD04JH5AAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAAdbAAAHWwEVi0IEAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAASBQTFRF////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA7v9mSAAAAF90Uk5TAAoLDA0ODxAREhMUGUBER0xPW1xdXl9gYWJjZGVmZ2hpamtsbW5vcHFyc3R1dnd4eXp7fH1+f5CSmJ2fpaaoub6/wMHCw8TFxsfIycrLzM3Oz9DR0tbX2Ofs7/H8/f7Rve9dAAACrklEQVR42u2a11bbQBRFx4CLbAjV9N57rymAaaZ3EjDG+v+/yBBIQD5jWWXmKg93P1mjO7O3X7y8tCQEwzAMwzAMwzAMw4QjFovSntx8KBYfNpNR+Zse7T88NkXjbyna7xRbovC3vtj/eGmN1h9FQZvDLwvaaP3ZMj91AfplQZbO367wy4L2aP10BR0V/LKgg8LfWdEvCzqj9VMUdLn6ZUGXYX/JrkLJaEE3+O/voaDbnL8H/ZkMFvRQ+oWgK+gF/13mdT1zBwW9lH6qgj7w36b/3kvfQkGfbn+/i19Z0E/pN18wAP6btHMifQMFA/r8g1X9yoJBg34LpyxjBUPgv7ZUc9Y1FAxp8dve/KoCW0PBMBx6ZVWata5geDisfwT9qcrTKSwYofTrLxiF4y5T7jtSl7BlNLh/zLdfWTCm0e/hWURSW8E4HHTh6VlI8gI2jgfxTwT0KwsmdPjPPT8LSp6HL5gM4VcWTPrzT6E/4Wd/AgumwvnPEv6+QeIsTMF0aL+yYNrr3hnYeurbLwtO4ZgZSn/wgln0x4P9ksWxYLb6rjnYdBLQLwtO4LA5Sn+QgnnYcBzCLwuO4cB5t/kFzX5lwYIvf50ISZ2PgkUYzYf2y4I8HLuonlwy4lcWLHnzH2nxy4IjLwXLxvzKguXymRX01wpt1GLBSjX/oUa/LDh0L1g17FcWrH7cXYObB5r9suAAJGuUfreCdbixXyMMULMPovXX9SyRX1mQFaK+QOVXFRTqxUb52p4xvyzYK7dtiDyhX1GQF8/OhZxRvyzIOX3P4qfTb/x9hJiz4Jf4TusvL/ghvny62iV5HyO2+0nZKMRXYr+j4Ju8jG+/fS5tkb2PEtt6f/i6/faXtzlXsJ92GgQhDTtPdiHX/LGQFuREoGQYhmEYhmEYhmH+T34D/Sg0XzsvtokAAAAASUVORK5CYII=)!important;
-webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
background-size: contain;
}
.accordion-button::after{
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAMAAAD04JH5AAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAAdbAAAHWwEVi0IEAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAASBQTFRF////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA7v9mSAAAAF90Uk5TAAoLDA0ODxAREhMUGUBER0xPW1xdXl9gYWJjZGVmZ2hpamtsbW5vcHFyc3R1dnd4eXp7fH1+f5CSmJ2fpaaoub6/wMHCw8TFxsfIycrLzM3Oz9DR0tbX2Ofs7/H8/f7Rve9dAAACrklEQVR42u2a11bbQBRFx4CLbAjV9N57rymAaaZ3EjDG+v+/yBBIQD5jWWXmKg93P1mjO7O3X7y8tCQEwzAMwzAMwzAMw4QjFovSntx8KBYfNpNR+Zse7T88NkXjbyna7xRbovC3vtj/eGmN1h9FQZvDLwvaaP3ZMj91AfplQZbO367wy4L2aP10BR0V/LKgg8LfWdEvCzqj9VMUdLn6ZUGXYX/JrkLJaEE3+O/voaDbnL8H/ZkMFvRQ+oWgK+gF/13mdT1zBwW9lH6qgj7w36b/3kvfQkGfbn+/i19Z0E/pN18wAP6btHMifQMFA/r8g1X9yoJBg34LpyxjBUPgv7ZUc9Y1FAxp8dve/KoCW0PBMBx6ZVWata5geDisfwT9qcrTKSwYofTrLxiF4y5T7jtSl7BlNLh/zLdfWTCm0e/hWURSW8E4HHTh6VlI8gI2jgfxTwT0KwsmdPjPPT8LSp6HL5gM4VcWTPrzT6E/4Wd/AgumwvnPEv6+QeIsTMF0aL+yYNrr3hnYeurbLwtO4ZgZSn/wgln0x4P9ksWxYLb6rjnYdBLQLwtO4LA5Sn+QgnnYcBzCLwuO4cB5t/kFzX5lwYIvf50ISZ2PgkUYzYf2y4I8HLuonlwy4lcWLHnzH2nxy4IjLwXLxvzKguXymRX01wpt1GLBSjX/oUa/LDh0L1g17FcWrH7cXYObB5r9suAAJGuUfreCdbixXyMMULMPovXX9SyRX1mQFaK+QOVXFRTqxUb52p4xvyzYK7dtiDyhX1GQF8/OhZxRvyzIOX3P4qfTb/x9hJiz4Jf4TusvL/ghvny62iV5HyO2+0nZKMRXYr+j4Ju8jG+/fS5tkb2PEtt6f/i6/faXtzlXsJ92GgQhDTtPdiHX/LGQFuREoGQYhmEYhmEYhmH+T34D/Sg0XzsvtokAAAAASUVORK5CYII=)!important;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* .c-container {
    background-color: aqua;
} */

.accordion-button{
    background-color: rgba(197, 206, 224, 0.24);
}
.accordion-button:not(.collapsed) {
    background-color: rgba(197, 206, 224, 0.24);
}
.breadcrumb {
    margin-bottom: 0;
    background-color: white;
}

