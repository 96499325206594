.accordion-button{
    background-color: rgba(197, 206, 224, 0.24);
}
.accordion-button:not(.collapsed) {
    background-color: rgba(197, 206, 224, 0.24);
}
.breadcrumb {
    margin-bottom: 0;
    background-color: white;
}
